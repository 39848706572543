import React, {useState,useEffect} from 'react'
import {useTheme} from '../components/Theming'
import Container from '../components/Container'
import {graphql,Link} from 'gatsby'
import Layout from '../components/Layout'
import { css, keyframes } from '@emotion/core'
import kultur from './kultur'
//import Img from 'gatsby-image'
import SEO from '../components/SEO'
import uniq from 'lodash/uniq'



const Kulturtipps = ({data}) => {
 
    //const [st,setSt]=useState(false)
   //const [flag,setFlag]=useState(false)
   
   // const [text,setText]=useState("")
  //  const [htext,setHText]=useState("")
    const [bilder,setBilder]=useState("")
    const [wahl,setWahl]=useState("Alle")
    const [tipps,setTipps]=useState("")
    const [kategorien,setKategorien]=useState("")
    const [all,setAlle]=useState(null)

    const [loading,setLoading]=useState(false)
    
    //const [menge,setAnzahl]=useState("")
   
    const theme = useTheme()
    const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -30px, 0);
  }

  70% {
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
`

    
    //const [menge,setAnzahl]=useState("")
   
    //const theme = useTheme()
   
 
  let kult = kultur.kulturdaten
 
    
    /* function los(daten){
      let mdat=[]
       daten.map((item,index) => {
        
       /*  let a=item.src
        let b=a.lastIndexOf('/')
        let c = a.slice(b+1,a.length)
        let d=c.split('.')
        let arr= parseInt(d[0]) 
        
        return mdat.push({src:item.src}) 
       })
     
      
      return mdat
      
    } */
    /* useEffect(()=>{
        if(typeof window!=="undefined"){
          let t = myRef.current.textContent
            setHText(t)
            //setFlag(!flag)
            myRef.current.textContent=t
        }

    },[]) 

    useEffect(()=>{
      if(typeof window!=="undefined"){
          setText(myRef.current.textContent)
          
      }

  },[text])
*/
function los(daten){
  let mdat=[]
   daten.map((item,index) => {
   
    let a=item.src
    let b=a.lastIndexOf('/')
    let c = a.slice(b+1,a.length)
    let d=c.split('.')
   let arr= parseInt(d[0])
          return mdat.push({item:item,id:arr}) 
   })
  mdat.sort((a,b) => (parseInt(a.id) > parseInt(b.id)) ? 1: -1)
 
  return mdat
  
}
useEffect(()=>{
  let kati=['Alle']

 if(typeof window!=="undefined" && kult){
  
   setTipps(kult)
   setAlle(kult)
   
  kult.forEach(element => {
    kati.push(element.kat)
  });
   setKategorien(uniq(kati))
 }
},[kult])



   useEffect(()=>{
     
    if(typeof window!=="undefined"){
      function makeImg(){
       
        const myImages= data.cultur.nodes.map((item,index)=> {
         
         return item.childImageSharp.fluid
        })
       
        let bi =los(myImages)
       
       
       setBilder(bi)
       setLoading(true)
       
     }
     makeImg()
    }
  },[data])   



  

  useEffect(()=>{
 
    if(all){
   let k= all
     if(wahl!=="Alle"){
        let w = k.filter(item => item.kat===wahl) 
          setTipps(w)
    }else if(wahl==="Alle"){
      setTipps(all)
    }
  }
   },[wahl,all])
    /* function mach(){
    
       let t = htext
       setHText(t)
       setText(t)
       let tb = t.substring(0,200)  
       setSt(!st)
       if(st){
        myRef.current.textContent=tb
        
       }else{
        myRef.current.textContent=t

      
       }
    
      
      
    } */ 
   
    return(
        <Layout  site={data.site}>
        <SEO title="Kulturtipps auf Markt 1"
        description="Kulturtipps von Freunden von Markt 1" />
         
       <Container >
          
       <article>
         <h1>Aktuelle Kultur-, Film-  und andere Tipps</h1>
         <br/>
        
    {
      kategorien && 
      kategorien.map((item,index)=>{
          return <button css={css`
            padding:5px;
            margin:0 4px;
            color:${theme.colors.text};
            &:hover {
              background:blue;
              color:white;
            }
          `} 
           key={index} 
           onClick={()=>setWahl(item)}>
             <span style={{fontSize:'.8rem',textTransform:"uppercase"}}>{item}</span></button> 
      })
     
    }
   
   
        
    </article>

          

 <section css={css`
 display:flex;
 flex-wrap:wrap;
 flex-shrink:0;
 
`}
 
 
 >
    
  { loading ?
    
    
    tipps.map((item,index)=>{
      let i = parseInt(item.id,10)
     
      let ind = i-1

     

       return (
        <article key={index} css={css`
        display:flex;
        flex-direction:column;
        max-width:180px;
        max-height:180px;
        min-height:180px;
        min-width:180px;
        justify-content:flex-end;
        
        margin:2px;
        background: url(${bilder[ind].item.src}) no-repeat;
        background-size:cover;
        background-position:center;
         @media(max-width:750px){
           min-width:100%;
           min-height:300px;
         }
       `}
       
       >
          
          <Link css={css`
            color:white;
            text-align:center;
            background:rgba(122,122,122,.7);
            padding:2px;
            &:hover {
              background:${theme.colors.grey};
              color:white;
            }
          `}
          
           to={item.ml}><span style={{textTransform:"uppercase",letterSpacing:"0.09rem",fontSize:'.7rem'}}>{item.kat}</span></Link>
          
        
         </article>
       )
     })
   

:<Container>
  <div css={css`
    animation: ${bounce} 1s ease infinite;
  `}>
   <p>Laden Daten ... </p>
  </div>
</Container>
}
</section>            
  
  




  </Container>  
        </Layout>

    )
}
      

export default Kulturtipps

export const culturQuery = graphql`
    query {
        site {
         
          siteMetadata {
            title,
            keywords,
            author {
              name
            }
          }
        }
        cultur: allFile (sort:{fields:relativePath},filter: {sourceInstanceName: {eq: "cultur"}}){
          nodes {
           relativePath
           childImageSharp {
            fluid (maxWidth: 750) {
            ...GatsbyImageSharpFluid
            }
           } 
          
           
         }
         }
    }
    `


